<template>
    <div class="userConfigure">
        <div class="title">
            
        </div>

        <div class="headBox">
            <el-button type="primary" icon="el-icon-plus" size="mini" @click="addSh()">创建商户</el-button>
            <div class="right">
               
                <el-input placeholder="输入商户名称" clearable v-model="customerName" size="mini" @input="getData">
                    <template slot="prepend">商户名称:</template>
                </el-input>
                <div class="select">
                    <div class="selectTit">续费日期:</div>
                    <el-select v-model="renewalDate" clearable placeholder="请选择" size="mini" @change="getData">
                        <el-option
                            v-for="item in renewalDateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <table-list
            :ButtonWidth="300"
            :tableData="tableData"
            :colrs="colrs"
            :total="total"
            :pagesize="pagesize"
            :pageIndex="pageIndex"
            @currentChange="currentChange"
            @handleSizeChange="handleSizeChange"
        >
            <template #TableItem="scope">
                <span v-if="scope.colr.prop == 'disabled'">
                    {{ scope.row[scope.colr.prop] ? "禁用" : "开通" }}
                </span>
                <span v-else-if="scope.colr.prop == 'paymentPlatformType'">
                    {{ handPaymentPlatform(scope.row[scope.colr.prop]) }}
                </span>
            </template>
            <template #Button="scope">
                <!-- <el-button size="mini" type="text" @click="check(scope.row)">查看</el-button> -->
                <el-button size="mini" type="text" @click="edit(scope.row, 1)">编辑</el-button>
                <el-button
                    size="mini"
                    type="text"
                    style="color: #f56c6c"
                    v-if="!scope.row.disabled"
                    @click="changeType(scope.row, scope.row.disabled)"
                    >禁用</el-button
                >
                <el-button
                    size="mini"
                    type="text"
                    style="color: #67c23a"
                    v-else-if="scope.row.disabled"
                    @click="changeType(scope.row, scope.row.disabled)"
                    >开启</el-button
                >
                <el-button size="mini" type="text" style="color: #f56c6c" @click="del(scope.row)">删除</el-button>
                <el-button size="mini" type="text" @click="addBusiness(scope.row)">平台管理</el-button>
                <el-button size="mini" type="text" @click="goAccount(scope.row)">账户管理</el-button>
                <el-button size="mini" type="text" @click="inout(scope.row,true)" v-if="!scope.row.adminJoin">加入</el-button>
                <el-button size="mini" type="text" @click="inout(scope.row,false)" v-else>退出</el-button>
            </template>
        </table-list>

        <el-dialog :visible.sync="dialogVisible" width="30%" :show-close="false" center :title="title">
            <el-form :model="userForm" ref="userForm" label-width="120px" class="userForm" :rules="rules">
               
                <el-form-item label="租户名称" prop="name">
                    <el-input
                        size="small"
                        v-model="userForm.name"
                        placeholder="输入商户名称"
                        style="width: 352px"
                    ></el-input>
                </el-form-item>
                <el-form-item label="是否禁用" prop="disabled">
                    <el-switch v-model="userForm.disabled" active-color="#13ce66" inactive-color="#909399"> </el-switch>
                </el-form-item>
                <el-form-item label="开通日期" prop="openingDate">
                    <el-date-picker
                        v-model="userForm.openingDate"
                        type="date"
                        size="small"
                        style="width: 352px"
                        placeholder="选择日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="续约日期" prop="renewalDate">
                    <el-date-picker
                        v-model="userForm.renewalDate"
                        type="date"
                        size="small"
                        style="width: 352px"
                        placeholder="选择日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit('userForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import TableList from "../../components/comm/TableList.vue";
import businessConfigureApi from "../../api/businessConfigureApi";
import userApi from "../../api/userApi"
export default {
    name: "userConfigure",
    components: { TableList },
    data() {
        return {
            tableData: [],
            colrs: [
                { label: "租户ID", prop: "id", width: 300 },
                { label: "租户名称", prop: "name", width: 300 },
                // { label: "支付平台", prop: "paymentPlatformTypeDesc" },
                { label: "开通日期", prop: "openingDate" },
                { label: "续费日期", prop: "renewalDate" },
                { label: "启用状态", prop: "disabled" }
            ],
            total: 0,
            pagesize: 20,
            pageIndex: 1,
            id: "",
            customerName: "",
            renewalDate: "",
            renewalDateOptions: [
                {
                    label: "正序",
                    value: "ASC"
                },
                {
                    label: "倒序",
                    value: "DESC"
                }
            ],

            rules: {
                // id: [{ required: true, message: "未输入商户ID", trigger: "blur" }],
                name: [{ required: true, message: "未输入商户名称", trigger: "blur" }],
                openingDate: [{  required: true, message: "未选择开通日期", trigger: "change" }],
                renewalDate: [{  required: true, message: "未选择续约日期", trigger: "change" }],
              
            },

            dialogVisible: false,

            userForm: {
                disabled:false
            },

            title:'创建商户'
        };
    },
    mounted() {
        this.getData();
      
    },
    methods: {
        handPaymentPlatform(item) {
            switch (item) {
                case "TLT":
                    return "通联支付";
                case "HEEPAY":
                    return "汇付宝";
            }
        },
        getData() {
            businessConfigureApi
                .getPageList({
                    pagesize: this.pagesize,
                    pageNumber: this.pageIndex,
                    name: this.customerName || null,
                    merchantId: this.id || null,
                    renewalDateOrderType: this.renewalDate || null
                })
                .then(res => {
                    if (res.success) {
                        this.tableData = res.data;
                        if (this.$utils.storage.get('admJoinObj') && this.$utils.storage.get('admJoinObj').id) {
                            let admJoinObj=this.$utils.storage.get('admJoinObj')
                            this.tableData.forEach(item=>{
                                if (item.id==admJoinObj.id) {
                                    item.adminJoin=true
                                }
                            })
                        }
                       

                    }
                });
        },
        addSh(){
            this.dialogVisible=true
            this.$set(this,'userForm',{})
            this.$set(this.userForm,'disabled',false)
             this.title='创建商户'
        },
        addBusiness(row) {

            this.$router.push({ name: "platformList", query: { tenantId: row.id, name: row.name } });
        },
      
        edit(row, t) {
            
            this.dialogVisible=true
            this.userForm=row
            this.title='编辑商户'
        },
        goAccount(row) {
            this.$router.push({ name: "accountConfigure", query: { tenantId: row.id, name: row.name } });
           
        },
        changeType(row, t) {
            if (t) {
                businessConfigureApi
                    .enable({
                        id: row.id
                    })
                    .then(res => {
                        if (res.success) {
                            this.$message({
                                type: "success",
                                message: "操作成功"
                            });
                            this.getData();
                        } else {
                            this.$message({
                                type: "warning",
                                message: res.msg
                            });
                        }
                    });
            } else {
                businessConfigureApi
                    .disable({
                        id: row.id
                    })
                    .then(res => {
                        if (res.success) {
                            this.$message({
                                type: "success",
                                message: "操作成功"
                            });
                            this.getData();
                        } else {
                            this.$message({
                                type: "warning",
                                message: res.msg
                            });
                        }
                    });
            }
        },
        del(row) {
            businessConfigureApi
                .delete({
                    id: row.id
                })
                .then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: "删除成功"
                        });
                        this.getData();
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.msg
                        });
                    }
                });
        },
        submit(formName){
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (this.title=='编辑商户') {
                        businessConfigureApi
                            .edit(this.userForm)
                            .then(res => {
                                if (res.success) {
                                    this.$message({
                                        type: "success",
                                        message: "修改成功"
                                    });
                                    this.dialogVisible=false
                                    this.getData();
                                } else {
                                    this.$message({
                                        type: "warning",
                                        message: "修改失败"
                                    });
                                }
                            });
                    } else {
                        businessConfigureApi
                            .add(this.userForm)
                            .then(res => {
                                if (res.success) {
                                    this.$message({
                                        type: "success",
                                        message: "创建成功"
                                    });
                                    this.dialogVisible=false
                                    this.getData();
                                } else {
                                    this.$message({
                                        type: "warning",
                                        message: "创建失败"
                                    });
                                }
                            });
                    }
                } else {
                    return false;
                }
            });
        },
        check(row){
            this.$router.push({ name: "tenadntOrderList", query: { tenantId: row.id } });
        },
        getinfo(row) {
            userApi
                .info({
                    id: row.id
                })
                .then(res => {
                    this.$utils.storage.set("shInfo", res.data);
                 
                });
        },
        inout(row,e){
           
            if (e) {
                businessConfigureApi.inOut({
                    id:this.$utils.storage.get("userInfo").id,
                    tenantId:row.id
                }).then(res=>{
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: "加入成功"
                        });
                        this.$utils.storage.set("admJoinObj", row);
                        this.getData();
                        this.getinfo(row);
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.msg
                        });
                    }
                })
            }else{
                businessConfigureApi.inOut({
                    id:this.$utils.storage.get("userInfo").id,
                    tenantId:''
                }).then(res=>{
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: "退出成功"
                        });
                        this.$utils.storage.remove("admJoinObj");
                        this.getData();
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.msg
                        });
                    }
                })
            }
        },

        currentChange(pageIndex) {
            this.pageIndex = pageIndex;
        },
        handleSizeChange(pagesize) {
            this.pageIndex = 1;
            this.pagesize = pagesize;
        }
    }
};
</script>
<style lang="scss" scoped>
.userConfigure {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    .title {
        display: flex;
        align-items: center;
        // margin-bottom: 30px;
        .tit {
            font-size: 20px;
            font-weight: 700;
        }
        .hz {
            display: flex;
            div {
                margin-right: 30px;
                font-size: 14px;
                span {
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }
    }

    .headBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
            gap: 10px;
            .select {
                display: flex;
                align-items: center;
                .selectTit {
                    background-color: #f5f7fa;
                    color: #909399;
                    vertical-align: middle;
                    display: table-cell;
                    position: relative;
                    border: 1px solid #dcdfe6;
                    border-right: 0;
                    border-radius: 4px 0 0 4px;
                    padding: 0 20px;
                    height: 26px;
                    line-height: 26px;
                    white-space: nowrap;
                }
                .el-select {
                    width: 180px;
                    /deep/.el-input__inner {
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }
    }

    /deep/.el-table .cell {
        line-height: normal !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    /deep/ .el-button--text {
        height: 16px;
        padding: 0;
    }
}
</style>
